import React, { useState } from "react";
import "./Login.css";
import axios from "axios";
import { baseUrl } from "../../utils/constant_api";
import logo from "../../images/logo.png";
import { FcGoogle } from "react-icons/fc";
import { GrLinkedin } from "react-icons/gr";

const Login = () => {
 const [isSignUp, setIsSignUp] = useState(false);
  const [data, setData] = useState({
    nom: "",
    prenom: "",
    adresse: "",
    codePostal: "",
    ville: "",
    email: "",
    pass: "",
    confirmpass: "",
  });
  const [loading, setLoading] = useState(false);
  const [errorMessage, setError] = useState("");
  const handleChange = (e) => {
    setData({ ...data, [e.target.name]: e.target.value });
  };

  const stateChange = (e) => {
    e.preventDefault();
    setIsSignUp((prev) => !prev)
  }

  const onSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);
    axios
      .post(`${baseUrl}/auth/login`, data)
      .then((res) => {
        if (res.data.status === "ok") {
          localStorage.setItem("token", res.data.token);
          const token = localStorage.getItem("token");
          axios
            .get(`${baseUrl}/auth/me`, {
              headers: {
                "x-access-token": `${token}`,
              },
            })
            .then((response) => {
              if (response.data.status === "ok") {
                console.log(response.data.user);
                localStorage.setItem('userRole', response.data.user.role)
                response.data.user.role === "client"
                  ? (window.location.href = "/client-monespace")
                  : (window.location.href = "/artisan-monespace");
                }
                setLoading(false);
            });
        } else {
          setError(res.data.message);
          setLoading(false);
        }
      })
      .catch((error) => {
        console.log(error);
        setError(error.response?.data?.message);
        setLoading(false);
      });
  };

  const onSignUp = async (e) => {
    e.preventDefault();
    setLoading(true);
    if (data.pass !== data.confirmpass) {
      setError("Les mots de passe ne correspondent pas");
      setLoading(false);
      return;
    }

    const passwordRegex =
      /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[!@#$%^&*]).{8,20}$/;
    if (!passwordRegex.test(data.pass)) {
      setError(
        "Le mot de passe doit contenir au moins 1 chiffre, 1 majuscule, 1 caractère spécial et comporter entre 8 et 20 caractères"
      );
      setLoading(false);
      return;
    }

    axios
      .post(`${baseUrl}/auth/register`, data)
      .then((res) => {
        setLoading(false);
        if (res.data.status === "ok") {
          localStorage.setItem("email", data.email);
          window.location.href = "/confirm";
        } else {
          setError(res.data.message);
          setLoading(false);
        }
      })
      .catch((error) => {
        setError(error.response.data.message);
        setLoading(false);
      });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    if (isSignUp) {
      onSignUp(e);
    } else {
      onSubmit(e);
    }
  };

  return (
    <div className="innerWidth paddings">
      <div className="w-full">
        <main className="w-full flex flex-col items-center justify-center bg-gray-50 p-4">
          <div className="w-full space-y-6 text-gray-600 md:px-8 md:w-3/4">
            <div className="text-center">
              <img src={logo} width={150} className="mx-auto" />
              <div className="mt-2 space-y-2">
                <h3 className="text-gray-800 text-2xl font-bold sm:text-3xl">
                  {isSignUp
                    ? "Inscrivez vous c'est gratuit"
                    : "Connectez vous à votre compte"}
                </h3>

                <p className="">
                  {isSignUp ? "j'ai déja un compte ! " : "Pas de compte ? "}
                  {""}
                  <a
                    href=""
                    className="font-medium text-indigo-600 hover:text-indigo-500"
                  >
                    <span onClick={stateChange}>
                      {isSignUp ? "Se connecter" : "Céer un compte!"}
                    </span>
                  </a>
                </p>
              </div>
            </div>
            <div className="bg-white shadow px-8 py-4  sm:p-6 sm:rounded-lg">
              <div className="grid grid-cols-2 gap-x-2 md:px-8 my-4">
                <button className="flex items-center justify-center py-2.5 border rounded-lg hover:bg-gray-50 duration-150 active:bg-gray-100">
                  <FcGoogle className="w-5 h-5" />
                </button>
                <button className="flex items-center justify-center py-2.5 border rounded-lg hover:bg-gray-50 duration-150 active:bg-gray-100">
                  <GrLinkedin className="w-5 h-5 text-blue-500" />
                </button>
              </div>
              <div className="relative md:px-8">
                <span className="block w-full h-px bg-gray-300"></span>
              </div>

              {errorMessage !== "" ? (
                <div className="flex items-center justify-center m-2 gap-2">
                  <i className="fa fa-warning text-danger fa-2x"></i>
                  <p className="text-red-700">{errorMessage}</p>
                </div>
              ) : (
                <span></span>
              )}
              <form className="space-y-5 md:px-8 my-4">
                {isSignUp ? (
                  <div>
                    <div className="md:gap-2">
                      <div className="">
                        <label className="font-medium mt-2">Nom</label>
                        <input
                          type="text"
                          placeholder="Nom"
                          name="nom"
                          onChange={handleChange}
                          value={data.nom}
                          className="w-full mt-1 px-3 py-2 text-gray-500 bg-transparent outline-none border focus:border-indigo-600 shadow-sm rounded-lg"
                          required
                        />
                      </div>
                      <div className="">
                        <label className="font-medium mt-2">
                          Adresse E-mail
                        </label>
                        <input
                          placeholder="Adresse E-mail"
                          type="email"
                          name="email"
                          onChange={handleChange}
                          value={data.email}
                          required
                          className="w-full mt-1 px-3 py-2 text-gray-500 bg-transparent outline-none border focus:border-indigo-600 shadow-sm rounded-lg"
                        />
                      </div>
                    </div>
                    <div className="mt-2">
                      <label className="font-medium mt-2">Adresse</label>
                      <div className="md:flex md:gap-2">
                        <input
                          type="adresse"
                          placeholder="Adresse N° Rue"
                          className="w-full md:w-1/3 mt-1 px-3 py-2 text-gray-500 bg-transparent outline-none border focus:border-indigo-600 shadow-sm rounded-lg"
                          name="adresse"
                          onChange={handleChange}
                          value={data.adresse}
                          required
                        />
                        <input
                          placeholder="Code postal"
                          type="number"
                          name="codePostal"
                          onChange={handleChange}
                          value={data.codePostal}
                          required
                          className="w-full md:w-1/3 mt-1 px-3 py-2 text-gray-500 bg-transparent outline-none border focus:border-indigo-600 shadow-sm rounded-lg"
                        />
                        <input
                          placeholder="Ville"
                          type="text"
                          name="ville"
                          onChange={handleChange}
                          value={data.ville}
                          required
                          className="w-full md:w-1/3 mt-1 px-3 py-2 text-gray-500 bg-transparent outline-none border focus:border-indigo-600 shadow-sm rounded-lg"
                        />
                      </div>
                    </div>

                    <div className="md:flex md:gap-2 mt-2">
                      <div className="md:w-1/2">
                        <label className="font-medium mt-2">Mot de passe</label>
                        <input
                          type="password"
                          placeholder="Mot de passe"
                          required
                          className="w-full mt-1 px-3 py-2 text-gray-500 bg-transparent outline-none border focus:border-indigo-600 shadow-sm rounded-lg"
                          name="pass"
                          onChange={handleChange}
                          value={data.pass}
                        />
                      </div>
                      <div className="md:w-1/2">
                        <label className="font-medium mt-2">
                          Confirmez le mot de passe
                        </label>
                        <input
                          placeholder="Confirmez le mot de passe"
                          type="password"
                          name="confirmpass"
                          onChange={handleChange}
                          value={data.confirmpass}
                          required
                          className="w-full mt-1 px-3 py-2 text-gray-500 bg-transparent outline-none border focus:border-indigo-600 shadow-sm rounded-lg"
                        />
                      </div>
                    </div>
                  </div>
                ) : (
                  <div>
                    <div>
                      <label className="font-medium">Adresse E-mail</label>
                      <input
                        type="email"
                        onChange={handleChange}
                        value={data.email}
                        name="email"
                        placeholder="Adresse E-mail"
                        className="w-full mt-2 px-3 py-2 text-gray-500 bg-transparent outline-none border focus:border-indigo-600 shadow-sm rounded-lg"
                        required
                      />
                    </div>
                    <div className="mt-2">
                      <label className="font-medium">Mot de passe</label>
                      <input
                        placeholder="Mot de passe"
                        type="password"
                        name="pass"
                        onChange={handleChange}
                        value={data.pass}
                        required
                        className="w-full mt-2 px-3 py-2 text-gray-500 bg-transparent outline-none border focus:border-indigo-600 shadow-sm rounded-lg"
                      />
                    </div>
                  </div>
                )}
                {loading ? (
                  <i className="fa fa-spinner fa-spin fa-2x"></i>
                ) : (
                  <button
                    className="w-full px-4 py-2 text-white font-medium bg-red-500 hover:bg-red-900 active:bg-red-700 rounded-lg duration-150"
                    type="submit"
                    onClick={(e) => handleSubmit(e)}
                  >
                    {isSignUp ? "S'inscrire" : "Connexion"}
                  </button>
                )}
              </form>
            </div>
            {isSignUp ? (
              <div></div>
            ) : (
              <div className="text-center">
                <a href="" className="hover:text-indigo-600">
                  Mot de passe oublié ?
                </a>
              </div>
            )}
          </div>
        </main>
      </div>
    </div>
  );
};

export default Login;
